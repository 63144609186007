import Swiper from 'swiper';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';

const swiper = new Swiper('#hero-slider', {
    modules: [Navigation, Pagination, EffectFade, Autoplay],
    slidesPerView: 1,
    crossFade: true,
    effect: 'fade',
    autoplay: {
        delay: 3000,
        pauseOnMouseEnter: true, 
    },
    navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
            return (
                '<span class="' +
                className +
                ' hero__nav-bullet">' +
                (index + 1) +
                '</span>'
            );
        },
    },
});
